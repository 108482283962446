import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import numeral from 'numeral'
import formatNumber from '../../Utils/formatNumber'
import styles from './styles.css'

const Spacer = () => <div style={{ flex: 1 }} />

const Expected = ({ project, projectTargetAmounts, fetchingProjectTargetAmounts }) => {

  return (
    <Card title='Expected' loading={fetchingProjectTargetAmounts}>

      <div className={styles.outlets}>

        <div className={styles.outlet}>
          <div className={styles.label}>Project Completion</div>
          <Spacer />
          <div className={styles.value}>
            <b>{`£${numeral(project.value_completed).format('0,0.00')}`}</b>
          </div>
          <div className={styles.suffix}>{`${numeral((project.value_completed / project.value_total) * 100).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Less Uplift</div>
          <Spacer />
          <div className={styles.value}>
            {formatNumber(projectTargetAmounts.client_completed_amount, '0,0.00', '£')}
          </div>
          <div className={styles.suffix}>{`${numeral((project.value_completed / project.value_total) * 100).format('0')}%`}</div>
        </div>

        <br />

        <div className={styles.outlet}>
          <div className={styles.label}>Expected Cost</div>
          <Spacer />
          <div className={styles.value}>
            <b>{`£${numeral(projectTargetAmounts.target_cost_completed_amount).format('0,0.00')}`}</b>
          </div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_cost_completed_percentage).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Expected Labour</div>
          <Spacer />
          <div className={styles.value}>{`£${numeral(projectTargetAmounts.target_labour_completed_amount).format('0,0.00')}`}</div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_labour_completed_percentage).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Expected Other</div>
          <Spacer />
          <div className={styles.value}>{`£${numeral(projectTargetAmounts.target_other_completed_amount).format('0,0.00')}`}</div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_other_completed_percentage).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Expected Profit</div>
          <Spacer />
          <div className={styles.value}>
            <b>{`£${numeral(projectTargetAmounts.target_profit_completed_amount).format('0,0.00')}`}</b>
          </div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_profit_completed_percentage).format('0')}%`}</div>
        </div>

        <br />

        <div className={styles.outlet}>
          <div className={styles.label}>Hourly Rate</div>
          <Spacer />
          <div>{project.hourly_rate}</div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Expected Man Days</div>
          <Spacer />
          <div className={styles.value}>
            <b>{numeral(projectTargetAmounts.man_days_expected_amount).format('0,0.00')}</b>
          </div>
          <div className={styles.suffix}>{`${numeral(((projectTargetAmounts.man_days_expected_percentage))).format('0')}%`}</div>
        </div>

      </div>

    </Card>
  )

}

Expected.propTypes = {
  project: PropTypes.object.isRequired,
  projectTargetAmounts: PropTypes.object.isRequired,
  fetchingProjectTargetAmounts: PropTypes.bool.isRequired,
}

export default Expected
