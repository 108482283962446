exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3itPMSc-zJwuOUS-zBxBRo {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n._1ZchpoAbV0CFh83VThsGbk {\n  display: flex;\n}\n\n._JOsGr4jWcBn6st1XBMXz {\n  color: #999;\n}\n\n.nbpZ4m9LuO3x_Aym_QDoT {\n  width: 120px;\n  text-align: right;\n}\n\n._2rqXJPPLWgKDDLfEb7AF6A {\n  width: 50px;\n  text-align: right;\n  color: #999;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/TargetCosts/Components/LiveLabour/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,SAAS;CACV;;AAED;EACE,cAAc;CACf;;AAED;EACE,YAAY;CACb;;AAED;EACE,aAAa;EACb,kBAAkB;CACnB;;AAED;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;CACb","file":"styles.css","sourcesContent":[".outlets {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.outlet {\n  display: flex;\n}\n\n.label {\n  color: #999;\n}\n\n.value {\n  width: 120px;\n  text-align: right;\n}\n\n.suffix {\n  width: 50px;\n  text-align: right;\n  color: #999;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"outlets": "_3itPMSc-zJwuOUS-zBxBRo",
	"outlet": "_1ZchpoAbV0CFh83VThsGbk",
	"label": "_JOsGr4jWcBn6st1XBMXz",
	"value": "nbpZ4m9LuO3x_Aym_QDoT",
	"suffix": "_2rqXJPPLWgKDDLfEb7AF6A"
};