/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import formatNumber from '../../Utils/formatNumber'
import styles from './styles.css'

const Spacer = () => <div style={{ flex: 1 }} />

const LiveLabour = ({ projectTargetAmounts, fetchingProjectTargetAmounts }) => (
  <Card title='Live Labour' loading={fetchingProjectTargetAmounts}>

    <div className={styles.outlets}>

      <div className={styles.outlet}>
        <div className={styles.label}>Total</div>
        <Spacer />
        <div className={styles.value}>
          <b>{formatNumber(projectTargetAmounts.live_labour_total, '0,0.00', '£')}</b>
        </div>
      </div>

      <br />

      <div className={styles.outlet}>
        <div className={styles.label}>Timesheet Postings</div>
        <Spacer />
        <div className={styles.value}>{formatNumber(projectTargetAmounts.timesheet_posting_total_cost, '0,0.00', '£')}</div>
      </div>

      <div className={styles.outlet}>
        <div className={styles.label}>Price Work</div>
        <Spacer />
        <div className={styles.value}>{formatNumber(projectTargetAmounts.sub_project_complete_amount, '0,0.00', '£')}</div>
      </div>

    </div>

  </Card>
)

LiveLabour.propTypes = {
  projectTargetAmounts: PropTypes.object.isRequired,
  fetchingProjectTargetAmounts: PropTypes.bool.isRequired,
}

export default LiveLabour
