import { connect } from 'react-redux'
import LiveLabour from './LiveLabour'

const mapStateToProps = (state) => ({
  projectTargetAmounts: state.screens.ProjectView.TargetCosts.projectTargetAmounts,
  fetchingProjectTargetAmounts: state.screens.ProjectView.TargetCosts.fetchingProjectTargetAmounts,
})

const actionCreators = {
  //
}

export default connect(mapStateToProps, actionCreators)(LiveLabour)
